<template>
  <div>
    <div class="md-layout vasion-calc-height">
      <Loading
        class="vasion-loading-indicator"
        :active.sync="isLoading"
        :is-full-page="false"
        :color="loaderColor"
        loader="dots"
        :background-color="loaderBackgroundColor"
      />
      <div v-if="activeForm" class="detailsDiv md-layout-item">
        <div class="innerDiv">
          <div class="md-layout top-padding">
            <div id="form-name" class="md-layout-item">
              <form v-show="creatingNewForm" id="create-new-form">
                <VasionInput
                  id="new-form-name"
                  v-model="newFormName"
                  name="new-form-name"
                  class="input-style"
                  inputType="top-white"
                  :title="`NEW ${$formsLabel.toUpperCase()} NAME`"
                  :placeholder="`Enter ${$formsLabel} Name...`"
                  @input="markAsDirty"
                />
                <VasionButton id="index-new-form-cancel" :classProp="'text'" @vasionButtonClicked="goToAllForms()">
                  CANCEL
                </VasionButton>
                <VasionButton
                  id="index-new-form-submit"
                  :classProp="'primary'"
                  :isDisabled="!newFormName"
                  @vasionButtonClicked="addNewForm()"
                >
                  CREATE
                </VasionButton>
              </form>
              <div
                v-show="!creatingNewForm"
                v-if="activeForm"
                id="active-form-name"
                class="vasion-page-title"
              >
                <VasionInput
                  id="new-form-title"
                  v-model="formName"
                  name="new-form-title"
                  class="input-style"
                  inputType="title-input"
                  :isTitleInput="true"
                  @input="markAsDirty"
                />
              </div>
            </div>
            <div class="md-layout-item right-text-align">
              <VasionButton
                v-show="!creatingNewForm"
                id="index-form-submit"
                :classProp="'primary'"
                :isDisabled="!areFormsDirty || editingField"
                :ripple="true"
                @vasionButtonClicked="submitForm()"
              >
                Save {{ $formsLabel }}
              </VasionButton>
            </div>
          </div>
          <div v-show="!creatingNewForm" class="md-layout tab-div">
            <VasionButton id="tab-field-table" :classProp="calculatedClass('fields')" @vasionButtonClicked="updateSelectedTab('fields')">
              {{ $fieldsLabelPlural }}
            </VasionButton>
            <VasionButton id="tab-form-layouts" :classProp="calculatedClass('layouts')" @vasionButtonClicked="updateSelectedTab('layouts')">
              {{ $layoutsLabelPlural }}
            </VasionButton>
            <VasionButton id="tab-security" :classProp="calculatedClass('security')" @vasionButtonClicked="updateSelectedTab('security')">
              Permissions
            </VasionButton>
          </div>
          <div v-show="!creatingNewForm && selectedTabName === 'fields'" id="fields-tab" class="top-padding">
            <div class="md-layout">
              <div class="attributeHeaderDetails">
                <VasionInput
                  v-model="template"
                  class="input-style"
                  inputType="top-white"
                  title="FILE NAME FORMAT"
                  placeholder="Enter File Name Format..."
                  @input="markAsDirty"
                />
              </div>
            </div>
            <table class="field-header-table">
              <tr>
                <td>
                  <label class="vasion-page-subheader">{{ $fieldsLabelPlural }}</label>
                </td>
                <td class="field-header-filter-column">
                  <VasionInput
                    id="field-filter-input"
                    v-model="fieldFilterText"
                    class="search-padding"
                    name="field-filter-input"
                    placeholder="Quick find..."
                    inputType="search-gray"
                    @input="markAsDirty"
                  />
                </td>
                <td class="field-header-add-field-column">
                  <VasionButton
                    :classProp="'secondary'"
                    :isRaised="false"
                    :isDisabled="false"
                    :ripple="true"
                    @vasionButtonClicked="$refs.newFieldDialog.toggleAddNewField()"
                  >
                    Add Field
                  </VasionButton>
                </td>
              </tr>
            </table>
            <div id="field-list" class="vasion-html-table-default">
              <table>
                <thead>
                  <tr>
                    <th class="fields-column">
                      <label class="vasion-html-table-header">Display Label</label>
                    </th>
                    <th class="fields-column">
                      <label class="vasion-html-table-header">Unique Name</label>
                    </th>
                    <th class="field-mandatory-column fields-column">
                      <label class="vasion-html-table-header">Mandatory</label>
                    </th>
                    <th class="field-type-column fields-column">
                      <label class="vasion-html-table-header">Field Type</label>
                    </th>
                    <th class="controls-column" />
                  </tr>
                </thead>
                <tbody class="scrollTBody">
                  <tr v-for="(field, index) in filteredFields()" :key="index" :class="{'lightestGreyBackground': index % 2 === 0 }">
                    <td class="pointer fields-column" @click="toggleFieldEdit(field.name)">
                      <label class="vasion-html-table-field pointer">{{ field.title }}</label>
                    </td>
                    <td class="pointer fields-column" @click="toggleFieldEdit(field.name)">
                      <label class="vasion-html-table-field pointer">{{ field.name }}</label>
                    </td>
                    <td class="field-mandatory-column pointer fields-column" @click="toggleFieldEdit(field.name)">
                      <label class="vasion-html-table-field pointer">{{ field.mandatory ? 'Yes' : 'No' }}</label>
                    </td>
                    <td class="field-type-column pointer fields-column" @click="toggleFieldEdit(field.name)">
                      <label class="vasion-html-table-field pointer">{{ fieldTypeText(field.type) }}</label>
                    </td>
                    <td class="controls-column right-text-align">
                      <VasionButton
                        v-show="!fieldFilterText"
                        :isRaised="false"
                        :isDense="false"
                        :icon="'VasionArrowLargeUpward'"
                        :isDisabled="!shouldReorderUpShow(index)"
                        title="Move Field Up"
                        @vasionButtonClicked="reorderField('up', field.name)"
                      />
                      <VasionButton
                        v-show="!fieldFilterText"
                        :isRaised="false"
                        :isDense="false"
                        :icon="'VasionArrowLargeDownward'"
                        :isDisabled="!shouldReorderDownShow(index)"
                        title="Move Field Down"
                        @vasionButtonClicked="reorderField('down', field.name)"
                      />
                      <VasionButton
                        :isRaised="false"
                        :isDense="false"
                        :icon="'VasionEllipsisIcon'"
                        @vasionButtonClicked="ellipsisOpen($event, field, 'field')"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-show="!creatingNewForm && selectedTabName === 'layouts'" id="layouts-tab">
            <table class="field-header-table">
              <tr>
                <td>
                  <label class="vasion-page-subheader">All {{ $layoutsLabelPlural }}</label>
                </td>
                <td class="field-header-filter-column">
                  <VasionInput
                    id="layout-filter-input"
                    v-model="layoutFilterText"
                    class="search-padding"
                    name="layout-filter-input"
                    placeholder="Quick find..."
                    inputType="search-gray"
                  />
                </td>
                <td class="field-header-add-field-column">
                  <VasionButton
                    :classProp="'secondary'"
                    :isRaised="false"
                    :isDisabled="!containsMoreThanWorkflowFields"
                    :ripple="true"
                    @vasionButtonClicked="newLayoutClick()"
                  >
                    New {{ $layoutsLabel }}
                  </VasionButton>
                </td>
              </tr>
            </table>
            <div id="field-list" class="vasion-html-table-default">
              <table>
                <thead>
                  <tr>
                    <th class="icon-table-column" />
                    <th class="layout-name-table-column">
                      <label class="vasion-html-table-header">{{ $layoutsLabel }} Name</label>
                    </th>
                    <th>
                      <label class="vasion-html-table-header">Description</label>
                    </th>
                    <th class="icon-table-column" />
                  </tr>
                </thead>
                <tbody class="scrollTBodyFields">
                  <tr v-for="(formLayout, index) in filteredLayoutArray" :key="index" :class="{'lightestGreyBackground': index % 2 === 0 }">
                    <td class="icon-table-column pointer" @click="editFormLayout(formLayout.EFormID)">
                      <VasionLayoutIcon class="pointer" />
                    </td>
                    <td class="layout-name-table-column pointer" @click="editFormLayout(formLayout.EFormID)">
                      <label class="vasion-html-table-field pointer">{{ formLayout.Name }}</label>
                    </td>
                    <td class="pointer" @click="editFormLayout(formLayout.EFormID)">
                      <label class="vasion-html-table-field pointer">{{ formLayout.Description }}</label>
                    </td>
                    <td class="controls-column right-text-align">
                      <VasionButton
                        :isRaised="false"
                        :isDense="false"
                        :icon="'VasionEllipsisIcon'"
                        @vasionButtonClicked="ellipsisOpen($event, formLayout, 'form-layout')"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="!formLayoutArray || formLayoutArray.length === 0" class="no-form-layouts-div">
                <div>
                  <VasionLargeLayoutIcon />
                </div>
                <div class="no-form-layouts-label-div">
                  <label class="vasion-page-subheader">No {{ $layoutsLabelPlural }} Added to {{ $formsLabel }}</label>
                </div>
                <div class="no-form-layouts-label-div">
                  <label class="vasion-page-subheader-light">{{ $layoutsLabelPlural }} created for this {{ $formsLabel }} will appear here</label>
                </div>
              </div>
            </div>
          </div>
          <div v-show="!creatingNewForm && selectedTabName === 'security'" id="security-tab">
            <div>
              <VasionListSelection
                :available-fields.sync="availableUsers"
                :selected-fields.sync="accessUsers"
                :allow-field-ordering="false"
                :available-label-text="'All Users'"
                :selected-label-text="'Access Users'"
                :unique-id="'access-users'"
                @update:availableFields="markAsDirty"
                @update:selectedFields="markAsDirty"
                @update:addAll="markAsDirty"
                @update:removeAll="markAsDirty"
              />
            </div>
            <div class="separator-div" />
            <div>
              <VasionListSelection
                :available-fields.sync="availableGroups"
                :selected-fields.sync="accessGroups"
                :allow-field-ordering="false"
                :available-label-text="'All Groups'"
                :selected-label-text="'Access Groups'"
                :unique-id="'access-groups'"
                @update:availableFields="markAsDirty"
                @update:selectedFields="markAsDirty"
                @update:addAll="markAsDirty"
                @update:removeAll="markAsDirty"
              />
            </div>
          </div>
          <NewFieldDialog
            ref="newFieldDialog"
            :config="newFieldConfig"
            :fieldTypeList="fieldTypeList"
            :fields.sync="fields"
            @update:fields="updateTable"
            @promptUpdateAllFields="promptUpdateField"
          />
        </div>
      </div>
    </div>

    <VasionGeneralModal
      id="confirmLeave"
      :hideButtons="true"
      modalType="slot"
      :sync="showUpdateFieldsPrompt"
      @noButtonClick="showUpdateFieldsPrompt = !showUpdateFieldsPrompt"
    >
      <VasionConfirmationDialog :message="updatePromptMessage" @noButtonClick="showUpdateFieldsPrompt = !showUpdateFieldsPrompt" @yesButtonClick="updateFieldsInForms()" />
    </VasionGeneralModal>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog">
      <VasionConfirmationDialog :message="leaveMessage" @noButtonClick="cancelLeave()" @yesButtonClick="doLeave()" />
    </md-dialog>

    <VasionEllipsisMenu
      v-if="showEllipsisMenu"
      :buttonConfig="ellipsisButtonConfig"
      :style="ellipsisMenuTopStyle"
      :rowItem="ellipsisRowItem"
      @ellipsis-button-clicked="ellipsisButtonClicked"
      @close="ellipsisClose"
    />

    <VasionGeneralModal
      id="vasion-confirmation-div"
      :confirmButtonText="'YES'"
      :rejectButtonText="'NO'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="generalModalMessage"
      :sync="showGeneralModal"
      @confirmButtonClick="generalModalOKClick()"
      @noButtonClick="showGeneralModal = false"
    />

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import NewFieldDialog from '@/components/forms/NewFieldDialog.vue';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
    name: 'EditAttributeForm',
    components: {
      Loading,
      NewFieldDialog,
    },
    beforeRouteLeave(to, from, next) {
      if (this.areFormsDirty && !this.routeTo && !this.saving && to.name !== 'TheLogin') {
        this.routeTo = to
        this.toggleLeaveDialog()
      } else {
        this.routeTo = null
        next()
      }
    },
    data: function () {
      return {
        accessGroups: [],
        accessUsers: [],
        attributeFormDirtyState: false,
        availableGroups: [],
        availableUsers: [],
        creatingNewForm: false,
        deleteFieldName: '',
        deleteFormLayoutID: 0,
        deletingEntityId: '',
        editingField: false,
        editingFieldName: '',
        ellipsisButtonFieldConfig: {
          buttons: [
            {
              id: 'edit-field-button',
              name: 'Edit Field',
              icon: 'VasionEditIcon',
            },
            {
              id: 'delete-field-button',
              name: 'Delete Field',
              icon: 'VasionDeleteIcon',
            },
          ],
        },
        ellipsisButtonFormLayoutConfig: { buttons: [] },
        ellipsisOpenType: '',
        ellipsisRowItem: {},
        fieldFilterText: '',
        fieldId: 0,
        fieldOrder: 0,
        fields: [],
        fieldTypeList: [
          { id: 1, value: 'Textbox' },
          { id: 2, value: 'Dropdown' },
          { id: 3, value: 'Date' },
          { id: 6, value: 'Number' },
          { id: 7, value: 'URL' },
          { id: 8, value: 'Email' },
          { id: 9, value: 'Checkbox' },
          { id: 10, value: 'Radio Button' },
          { id: 11, value: 'Currency' },
          { id: 12, value: 'Signature Field' },
          { id: 13, value: 'Text Area' },
        ],
        formLayoutArray: [],
        formName: '',
        generalModalMessage: '',
        isLoading: false,
        layoutFilterText: '',
        loaderBackgroundColor,
        loaderColor,
        newFieldConfig: {
          fieldHidden: false,
          fieldLabel: '',
          fieldLength: 100,
          fieldType: {},
          fieldUniqueName: { name: '<New Field>' },
          fieldValues: '',
          mandatory: false,
          readOnly: false,
          systemDate: false,
          totalPageNo: false,
        },
        newFormName: '',
        piiData: false,
        saving: false,
        selectedTabName: 'fields',
        showEllipsisMenu: false,
        showGeneralModal: false,
        showLeaveDialog: false,
        showSnackbar: false,
        showUpdateFieldsPrompt: false,
        snackbarImage: false,
        snackbarSubTitle: '',
        snackbarTitle: '',
        template: '',
        updateFieldPayload: {},
      }
    },
    computed: {
      activeForm() { return this.$store.state.attributeForm.activeIndexForm },
      areFormsDirty() { return this.$store.state.forms.areFormsDirty },
      containsMoreThanWorkflowFields() {
        const fieldsLocal = this.filteredFields().filter((f) => {
          if (f.name === 'Workflow_Status' || f.name === 'Workflow_Approver' || f.name === 'Workflow_Due_Date' || f.value === 'Workflow_Initiator') {
            return false
          } else return true
        })
        return fieldsLocal.length > 0
      },
      desiredPendingRoute() { return this.$store.state.forms.desiredPendingRoute },
      ellipsisButtonConfig() { return this.ellipsisOpenType === 'form-layout' ? this.ellipsisButtonFormLayoutConfig : this.ellipsisButtonFieldConfig },
      filteredLayoutArray() {
        return !this.formLayoutArray ? [] : this.formLayoutArray.filter((formLayout) => {
          return formLayout.Name.toLowerCase().includes(this.lowerLayoutFilterString) || formLayout.Description.toLowerCase().includes(this.lowerLayoutFilterString)
        })
      },
      forms() { return this.$store.state.attributeForm.forms },
      groups() { return this.$store.state.common.groups },
      leaveMessage() {
        return this.activeForm.ID
          ? `Are you sure you want to leave? Changes to "${this.activeForm.name}" have not been saved.`
          : `Are you sure you want to leave? Changes to "${this.newFormName}" have not been saved.`
      },
      lowerFieldFilterString() { return !this.fieldFilterText ? '' : this.fieldFilterText.toLowerCase() },
      lowerLayoutFilterString() { return !this.layoutFilterText ? '' : this.layoutFilterText.toLowerCase() },
      newFormId() { return this.$store.state.attributeForm.newFormId },
      updatePromptMessage() { return 'Do you want to update all forms ?' },
      users() { return this.$store.state.common.users },
    },
    watch: {
      activeForm() {
        if (this.activeForm.ID) {
          this.isLoading = false
        }
      },
      desiredPendingRoute() { this.toggleLeaveDialog() },
    },
    created: function () {
      this.ellipsisButtonFormLayoutConfig.buttons = [
        {
          id: 'copy-link',
          name: 'Copy Link',
          icon: 'VasionLinkIcon',
        },
        {
          id: 'embed-form-layout',
          name: 'Copy Embedded Link',
          icon: 'VasionCodeIcon',
        },
        {
          id: 'HR',
          name: 'HR',
          isHorizontalRule: true,
        },
        {
          id: 'edit-form-layout-button',
          name: `Edit ${$layoutsLabel}`,
          icon: 'VasionEditIcon',
        },
        {
          id: 'delete-form-layout-button',
          name: `Delete ${$layoutsLabel}`,
          icon: 'VasionDeleteIcon',
        },
      ]
      this.selectedTabName = this.$store.state.forms.defaultFormTab ? this.$store.state.forms.defaultFormTab : 'fields'
      this.$store.dispatch('forms/setDefaultFormTab', '')

      if (this.$route.params.formID) {
        this.$store.dispatch('attributeForm/populateIndexFormAdminData').then(() => {
          this.getDetails(this.$route.params.formID)
        })
      } else {
        this.toggleCreateNewForm()
      }
      this.saving = false
      if (!this.creatingNewForm) {
        this.isLoading = true
      }
    },
    updated: function () {
      this.$store.dispatch('mainViews/changeMainNav', `configure-form-${this.$route.params.formID}`)
    },
    methods: {
      addNewForm: function () {
        if (this.newFormName.match(/[<>;:'"/\\|?*_=+{}[\],.`~!@#$%^&()]/)) {
          this.snackbarTitle = 'Special Character Detected'
          this.snackbarSubTitle = 'Name cannot include special character(s).'
          this.snackbarImage = false
          this.showSnackbar = true
          return
        }

        if (this.newFormName.trim() === '') {
          this.snackbarTitle = 'Only Spaces Detected'
          this.snackbarSubTitle = 'Name cannot only include spaces.'
          this.snackbarImage = false
          this.showSnackbar = true
          return
        }

        const payload = {
          Value: this.newFormName,
        }

        this.isLoading = true
        this.$store.dispatch('attributeForm/createNewForm', payload).then(() => {
          this.$store.dispatch('attributeForm/getForms')
          this.getDetails(this.newFormId)
          this.toggleCreateNewForm()
          this.$store.dispatch('forms/updateAreFormsDirty', false)
          this.isLoading = false
        })
      },
      calculatedClass(selectedTab) { return this.selectedTabName === selectedTab ? 'text-selected' : 'text' },
      async copyToClipboard(data, type) {
        let url
        let baseURL = window.location.href
        const lastIndex = baseURL.indexOf('/forms/editForm')
        baseURL = baseURL.substring(0, lastIndex)

        if (type === 'link') {
          url = `${baseURL}/formLink/${data.Name}/${data.EFormID}`
        } else {
          url = `<div style="width:100%;"><iframe src="${baseURL}/formLink/${data.Name}/${data.EFormID}" width="100%" height="100%" style="position:absolute" frameBorder="0" class="giphy-embed" allowFullScreen></iframe></div>`
        }

        let textCopySuccess = false
        try {
          // navigator is the better method, but can only be used in a secure context or localhost
          // meaning it fails in "http".
          // so, plan B is the hack in the "else" statement to add the text to a hidden text area and hope that works          
          if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(url)
            textCopySuccess = true
          } else {          
            const textArea = document.createElement("textarea")
            textArea.value = url
            // make the textarea out of viewport
            textArea.style.position = "fixed"
            textArea.style.left = "-999999px"
            textArea.style.top = "-999999px"
            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()
            
            textCopySuccess = document.execCommand('copy')
            textArea.remove();
          }
        } catch {
          textCopySuccess = false
        }

        this.snackbarTitle = textCopySuccess ? 'Copied!' : 'Could NOT Copy!'
        this.snackbarSubTitle = textCopySuccess ? 'Link copied to clipboard' : ''
        this.snackbarImage = true
        this.showSnackbar = true
      },
      async deleteField() {
        const payload = {
          fieldName: this.deleteFieldName,
          formID: this.activeForm.ID,
        }

        this.showGeneralModal = false
        this.isLoading = true
        const canRemove = await this.$store.dispatch('document/canRemoveAttributeField', payload)

        if (canRemove) {
          const index = this.fields.findIndex((f) => f.name === payload.fieldName)
          this.fields.splice(index, 1)
          this.fieldFilterText = ''

          const saveSuccess = await this.saveFormFields()
          if (saveSuccess) {
            this.snackbarTitle = `Successfully Removed Field "${payload.fieldName}"`
            this.snackbarSubTitle = ''
            this.snackbarImage = true
            this.showSnackbar = true
          }
        } else {
          this.snackbarTitle = 'Cannot Remove Field'
          this.snackbarSubTitle = ''
          this.snackbarImage = false
          this.showSnackbar = true
        }

        this.isLoading = false
      },
      cancelLeave() {
        this.routeTo = null
        this.toggleLeaveDialog()
      },
      clickedOutsideLeave() { this.routeTo = null },
      async deleteFormLayout() {
        if (this.deleteFormLayoutID <= 0) {
            return
          }

          this.isLoading = true
          const response = await this.$store.dispatch('common/deleteEForm', this.deleteFormLayoutID)

          this.showGeneralModal = false
          this.snackbarTitle = response ? `${$layoutsLabel} Deleted Successfully` : `Error Deleting ${$layoutsLabel}`
          this.snackbarSubTitle = response ? `The ${$layoutsLabel} has been removed` : 'Please contact your Administrator if the problem persists.'
          this.snackbarImage = response
          this.showSnackbar = true
          this.isLoading = false

          if (response) {
            this.loadFormLayoutsFromActiveForm()
          }
      },
      doLeave() {
        this.$store.dispatch('forms/updateAreFormsDirty', false)
        this.toggleLeaveDialog()
        this.isLoading = true

        if (this.routeTo && this.routeTo.name !== '') {
          if (this.routeTo.params) {
            this.$router.push({ name: this.routeTo.name })
          } else {
            this.$router.push({ name: this.routeTo.name, params: this.routTo.params })
          }

          this.routeTo = null
        } else {
          if (this.desiredPendingRoute.location === 'all-forms') {
            this.$router.push({ name: 'AllForms' })
          } else {
            this.$router.push({ name: 'EditForm', params: { formID: this.desiredPendingRoute.value } })
          }
          this.$store.dispatch('mainViews/changeMainNav', this.desiredPendingRoute.location)
        }
      },
      editFormLayout(formLayoutID) { this.$router.push({ name: 'TheNewFormView', params: { formID: this.activeForm.ID, formLayoutID: formLayoutID } }) },
      async editFormName() {
        const payload = {
                attributeFormID: this.activeForm.ID,
                attributeFormName: this.formName,
            }

        await this.$store.dispatch('attributeForm/renameForm', payload)
        this.updateNameInFormList(payload)
      },
      ellipsisButtonClicked(payload) {
        this.ellipsisClose()

        if (!payload || !payload.buttonId || !payload.item) {
          return
        }
        this.deletingEntityId = payload.buttonId
        switch (payload.buttonId) {
          case 'copy-link':
            this.copyToClipboard(payload.item, 'link')
            break;
          case 'edit-field-button':
            this.toggleFieldEdit(payload.item.name)
            break;
          case 'embed-form-layout':
            this.copyToClipboard(payload.item, 'embed')
            break;
          case 'delete-field-button':
            this.generalModalMessage = `Are you sure you want to delete ${payload.item.name}?`
            this.showGeneralModal = true
            this.deleteFieldName = payload.item.name
            break
          case 'edit-form-layout-button':
            this.editFormLayout(payload.item.EFormID)
            break
          case 'delete-form-layout-button':
            this.generalModalMessage = `Are you sure you want to delete ${payload.item.Name}?`
            this.showGeneralModal = true
            this.deleteFormLayoutID = payload.item.EFormID
            break
          default:
            break
        }
      },
      ellipsisClose() { this.showEllipsisMenu = false },
      ellipsisOpen(event, item, openType) {
        this.ellipsisOpenType = openType
        this.ellipsisClose()
        this.ellipsisRowItem = item
        let displayY = event.pageY
        let baseSize = 0

        if (this.ellipsisButtonConfig && this.ellipsisButtonConfig.buttons) {
          baseSize = this.ellipsisButtonConfig.buttons.length * 40
        }

        if (displayY + baseSize > document.documentElement.scrollHeight) {
          displayY -= baseSize
        }

        this.ellipsisMenuTopStyle = `top:${displayY}px;`
        this.showEllipsisMenu = true
      },
      fieldTypeText(fieldType) {
        const foundFieldType = this.fieldTypeList.find((element) => {
          return element.id === fieldType
        })
        return foundFieldType?.value
      },
      filteredFields() {
        return !this.fields ? [] : this.fields.filter((field) => {
          return field.name.toLowerCase().includes(this.lowerFieldFilterString) || field.title.toLowerCase().includes(this.lowerFieldFilterString)
        })
      },
      async generalModalOKClick() {
        if (this.deletingEntityId === 'delete-field-button') {
          this.deleteField()
        } else if (this.deletingEntityId === 'delete-form-layout-button') {
          this.deleteFormLayout()
        }
      },
      getDetails: function (formId) {
        this.$store.dispatch('attributeForm/getFormDetails', formId).then(() => {
          this.setDataFromActiveForm()
          this.markAsClean()
          this.creatingNewForm = false
          this.editingField = false
          this.formName = this.activeForm.name
        })
      },
      getIdValue: function (prefix, id) { return `${prefix}-${id}` },
      goToAllForms() { this.$router.push({ name: 'AllForms' }) },
      async loadFormLayoutsFromActiveForm() {
        const formLayoutResponse = await this.$store.dispatch('forms/getEFormsByFormID', this.activeForm.ID)
        this.formLayoutArray = formLayoutResponse && formLayoutResponse.EFormCollection && formLayoutResponse.EFormCollection.Values ? formLayoutResponse.EFormCollection.Values.slice() : []
      },
      markAsClean() { this.$store.dispatch('forms/updateAreFormsDirty', false) },
      markAsDirty() { this.$store.dispatch('forms/updateAreFormsDirty', true) },
      newLayoutClick() { this.$router.push({ name: 'TheNewFormView', params: { formID: this.activeForm.ID, formLayoutID: '0' } }) },
      promptUpdateField(payload) {
        this.showUpdateFieldsPrompt = !this.showUpdateFieldsPrompt
        this.updateFieldPayload = payload
      },
      reorderField: function (direction, fieldName) {
        // Get the field element and index for the field being moved
        const originalIndex = this.fields.findIndex((f) => f.name === fieldName);
        const fieldBeingMoved = this.fields[originalIndex]

        // Get the field element and index for the field being displaced
        const newIndex = direction === 'up' ? (originalIndex - 1) : (originalIndex + 1);

        // Set the new fieldOrder value for the field being moved
        const movedFieldOrder = this.fields[originalIndex].fieldOrder
        this.fields[originalIndex].fieldOrder = direction === 'up' ? (movedFieldOrder - 1) : (movedFieldOrder + 1);

        // Set the new fieldOrder value for the field being displaced
        const displacedFieldOrder = this.fields[newIndex].fieldOrder
        this.fields[newIndex].fieldOrder = direction === 'up' ? (displacedFieldOrder + 1) : (displacedFieldOrder - 1);

        // Now move the fields
        const newArray = this.fields.filter((f) => { // Remove the field being moved from the fields array
            return f.name !== fieldName
        })
        newArray.splice(newIndex, 0, fieldBeingMoved) // Stuff it back in, at it's new home

        this.fields = newArray;
        this.saveFormFields()
      },
      resetLocalFieldInfo() {
        this.editingFieldName = ''
        this.newFieldConfig.fieldUniqueName = { name: '<New Field>' }
        this.newFieldConfig.fieldLabel = ''
        // eslint-disable-next-line
        this.newFieldConfig.fieldType = this.fieldTypeList[0]
        this.newFieldConfig.fieldValues = ''
        this.newFieldConfig.fieldLength = 100
        this.fieldId = 0
        this.fieldOrder = 0
        this.newFieldConfig.fieldHidden = false
        this.newFieldConfig.mandatory = false
        this.piiData = false
        this.newFieldConfig.readOnly = false
        this.newFieldConfig.systemDate = false
        this.newFieldConfig.totalPageNo = false
      },
      async saveFormFields() {
        this.isLoading = true
        const payload = {
          ID: this.activeForm.ID,
          fields: this.fields,
        }
        const response = await this.$store.dispatch('attributeForm/saveAttributeFormFields', payload)
        const result = response?.errorMessage === ''

        if (!result) {
          this.snackbarTitle = 'Error'
          this.snackbarSubTitle = 'Unable to update form fields'
          this.snackbarImage = false
          this.showSnackbar = true
        }
        else {
          response.fields?.forEach(f => {
             const index = this.fields.findIndex(lf => lf.fieldID === 0 && lf.name === f.name)
             if (index >= 0) {
                this.fields[index].fieldID = f.fieldID
             }
          })
        }

        await this.$store.dispatch('forms/getAllFieldHeaders')

        this.isLoading = false
        return result
      },
      setDataFromActiveForm() {
        this.template = this.activeForm.documentNameTemplate
        this.formName = this.activeForm.name

        this.accessUsers = []
        this.accessGroups = []
        this.availableUsers = []
        this.availableGroups = []

        this.users.forEach((user) => {
          const newUser = {
            name: user.name,
            value: user.value,
          }

          if (this.activeForm.accessUserIDs && this.activeForm.accessUserIDs.includes(user.value)) {
            this.accessUsers.push(newUser)
          } else {
            this.availableUsers.push(newUser)
          }
        })

        this.groups.forEach((group) => {
          const newGroup = {
            name: group.name,
            value: group.value,
          }

          if (this.activeForm.accessGroupIDs && this.activeForm.accessGroupIDs.includes(group.value)) {
            this.accessGroups.push(newGroup)
          } else {
            this.availableGroups.push(newGroup)
          }
        })

        this.fields = this.activeForm.fields

        this.loadFormLayoutsFromActiveForm()
      },
      shouldReorderDownShow(sequence) { return this.activeForm && this.activeForm.fields && (sequence + 1) < this.activeForm.fields.length },
      shouldReorderUpShow(sequence) { return sequence > 0 },
      showFailureSaveMessage(messageDetail) {
        this.snackbarTitle = `Unable to update ${$formsLabel}`
        this.snackbarSubTitle = messageDetail
        this.snackbarImage = false
        this.showSnackbar = true
      },
      async submitForm() {
        if (!this.validateSaveData()) {
          return
        }

        this.isLoading = true
        if (this.formName !== this.activeForm.name) {
          await this.editFormName()
        }

        const form = {
          ID: this.activeForm.ID,
          accessGroupIDs: this.accessGroups ? this.accessGroups.map((group) => {
            return group.value
          }) : [],
          accessUserIDs: this.accessUsers ? this.accessUsers.map((user) => {
            return user.value
          }) : [],
          documentNameTemplate: this.template,
        }

        const success = await this.$store.dispatch('attributeForm/saveAttributeFormHeader', form)
        this.isLoading = false
        this.$store.dispatch('forms/updateAreFormsDirty', false)
        if (success.Value === 'True') {
          this.saving = true
          this.goToAllForms()
        } else {
          this.showFailureSaveMessage(success)
        }
      },
      toggleCreateNewForm: function () { this.creatingNewForm = !this.creatingNewForm },
      toggleFieldEdit: function (fieldName) { this.$refs.newFieldDialog.toggleFieldEdit(fieldName) },
      toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
      async updateFieldsInForms() {
        this.isLoading = true
        const response = await this.$store.dispatch('forms/updateFieldInEForms', this.updateFieldPayload)
        if (response === 'False') {
          this.snackbarTitle = 'Error!'
          this.snackbarSubTitle = 'Could not update all fields'
          this.snackbarImage = false
          this.showSnackbar = true
        }
        this.showUpdateFieldsPrompt = !this.showUpdateFieldsPrompt
        this.isLoading = false
      },
      updateNameInFormList: function (payload) {
          const form = this.forms.find((f) => f.value === payload.attributeFormID)
          if (form) {
              form.name = payload.attributeFormName
              this.activeForm.name = payload.attributeFormName
          }
      },
      updateSelectedTab(newSelectedTab) { this.selectedTabName = newSelectedTab },
      updateTable(fields) {
        this.fields = fields

        this.loadFormLayoutsFromActiveForm()
        this.saveFormFields()
      },
      validateSaveData() {
        this.formName = this.formName.trim()
        if (!this.formName) {
          this.showFailureSaveMessage(`The ${$formsLabel} Name Cannot be Blank or Only Include Whitespace`)
          return false
        }

        // validate field uniqueness
        let errorMessage = ''
        const uniqueFieldNames = []

        this.fields.map((field) => {
          if (uniqueFieldNames.includes(field.name)) {
            errorMessage += `${field.name} is not a unique field name. `
            return false
          }

          uniqueFieldNames.push(field.name)
          return true
        })

        if (errorMessage) {
          this.showFailureSaveMessage(errorMessage)
          return false
        }

        return true
      },
    },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #active-form-name::v-deep {
    input {
      color: black;
    }
  }

  .button-div {
    position: relative;
    left: 50%;
    top: 55%;
  }

  .tab-div {
    margin-left: -10px;
  }

  .field-dialog {
    width: 47%;
  }

  .dialogFooter {
    width: 100%;
    text-align: right;
  }

  .lightestGreyBackground {
    background-color: $grey-50;
  }

  .spacer {
    width: 2%;
  }

  .attributeHeaderDetails {
    width: 49%;
  }

  .top-padding {
    padding-top: 15px;
  }

  .right-text-align {
    text-align: right;
  }

  .pointer:hover {
    cursor: pointer;
  }

  .innerDiv {
    padding-left: 16px;
    padding-top: 7px;
    padding-right: 16px;
    width: 100%;

    .dialog {
      width: 750px;
      height: 325px;
    }
  }

  .detailsDiv {
    background-color: white;
  }

  .w100 {
    width: 100%;
  }

  .createFormDiv {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  .leftSideNavDiv {
    width: 304px;
    height: 100%;
    background-color: $grey-50;
    overflow: auto;
  }

  #field-list {
      min-width: 400px;
      .form-field-title {
          width: 200px;
          margin-left: 15px;
      }

      .form-field-type {
          width: 100px;
      }
      thead, tbody tr {
        display:table;
        width:100%;
        table-layout:fixed;/* even columns width , fix width of table too*/
      }
  }

  #index-form-submit {
      margin-top: 0px;
  }

  #name-edit-form, #create-new-field {
      padding: 10px;
      border: thin solid gray;
  }

  #create-new-form {
    padding: 10px;
    width: 500px;
    border: thin solid gray;
    text-align: right;
  }

  #create-new-field {
      padding: 10px;
      border: none;
  }

  #index-new-form-cancel, #index-form-name-cancel {
      margin-left: 5px;
  }

  .form-item {
    width: 100%;
  }

  .vasion-html-table-default
  {
    padding-top: 20px;
  }

  .controls-column {
    width: 125px;
    text-align: right;
  }

  .icon-table-column {
    width: 40px;
    text-align: right;
    fill: $grey-400;
  }

  .field-mandatory-column {
    width: 100px;
  }

  .field-type-column {
    width: 200px;
  }

  .field-header-table {
    width: 100%;
    margin-top: 20px;
  }

  .field-header-filter-column {
    text-align: right;
    width: 300px;
  }

  .field-header-add-field-column {
    text-align: right;
    width: 100px;
  }

  .layout-name-table-column {
    width: 40%;
  }

  .no-form-layouts-div {
    padding-top: 40px;
    width: 100%;
    text-align: center;
  }

  .no-form-layouts-label-div {
    margin-top: 10px;
  }

  .md-layout-item {
    width: 315px !important;
  }

  .scrollTBody {
    display: table-caption;
    caption-side: bottom;
    overflow: auto;
    max-height: calc(100vh - 385px);
  }
  .scrollTBodyFields {
    display: table-caption;
    caption-side: bottom;
    overflow: auto;
    max-height: calc(100vh - 315px);
  }

  table {
    table-layout: fixed;
    width: 100%;
  }
  .fields-column {
    width: 20%;
  }
  td.fields-column:nth-child(2) { // "Unique Name" column
    padding-left: 1px;
  }
  td.fields-column:nth-child(3) { // "Mandatory" column
    padding-left: 5px;
  }
  td.fields-column:nth-child(4) { // "Field Type" column
    padding-left: 6px;
  }
</style>
